import React, { useEffect, useState } from 'react';
import '../Styles/imageSlider.css';
import offer from "../assets/container-removebg-preview.png";
import Forklift from "../assets/Forklift.png";
import conDrop from "../assets/conDrop.png";
import FreightSolutions from "../assets/Freight_Solutions.png";
import TigerLogistic from "../assets/Tiger_Logistics.png";
import Ukpays from "../assets/UK_pays.png";

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [offer, Forklift, conDrop, FreightSolutions, TigerLogistic, Ukpays];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="slider container">
      <div className="slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div className="slide img-fluid " key={index}>
            <img src={image} alt={`Slide ${index}`} />
            <div className="overlay"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
