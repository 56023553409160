import React from 'react'
import AboutSectionOne from '../Components/AboutSectionOne'
const Aboutpage = () => {
  return (
    <div className='mt-60'>
      <AboutSectionOne/>
    </div>
  )
}

export default Aboutpage