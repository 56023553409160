import React from "react";
import ParcelTracking from "../Components/ParcelTracking";

const Trackingpage = () => {
  return (
    <div className="container my-12 py-8 mx-auto max-w-4xl"> {/* Added max-width and centered */}
      <ParcelTracking />
    </div>
  );
};

export default Trackingpage;
