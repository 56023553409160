import React from 'react';
import { useNavigate } from 'react-router-dom';

const QuoteBanner = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <section className="bg-gray-800 py-10 my-10">
      <div className="container mx-auto text-center text-white">
        <h2 className="text-3xl font-bold pt-5">
          "Delivering Excellence, One Shipment at a Time"
        </h2>
        <p className="">
          Our commitment to quality service is unmatched. Let us handle your logistics needs with precision and care.
        </p>
        <button
          onClick={handleContactClick}
          className="bg-white my-10 text-blue-600 font-semibold py-2 px-6 rounded-lg shadow-lg hover:bg-gray-200 transition-colors duration-300"
        >
          Contact Us
        </button>
      </div>
    </section>
  );
};

export default QuoteBanner;
