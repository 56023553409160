import React, { useState, useEffect, useRef } from "react";
import video from "../assets/5598972-uhd_3840_2160_30fps.mp4";

const VideoSection = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
          observer.disconnect(); // Stop observing after the video is visible
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the video is visible
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <section className="py-10 mt-10 bg-white">
      <div className="container mx-auto">
        <div className="row">
          {/* Video on the Left */}
          <div className="col-12 col-md-6 mb-6 md:mb-0">
            <div className="video-container">
              {isVideoVisible ? (
                <video
                  ref={videoRef}
                  className="w-full h-64 md:h-full rounded-lg object-cover"
                  src={video}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : (
                <div
                  ref={videoRef}
                  className="w-full h-64 md:h-full rounded-lg bg-gray-200"
                >
                  {/* Placeholder for video */}
                </div>
              )}
            </div>
          </div>

          {/* Service Words on the Right */}
          <div className="col-12 col-md-6 flex items-left m-auto text-justify">
            <div className="text-left md:text-left ">
              <h3 className="text-2xl font-bold text-gray-800 mb-4 font-serif">
                Comprehensive Logistic Solutions
              </h3>
              <p className="text-gray-600 mb-4 font-serif fw-medium">
                We provide a wide range of logistic services to meet your needs,
                from fast shipping to secure packaging and real-time tracking.
                Our customers overseas require a global and local responsive
                secure support network, which we expertly deliver worldwide.
                {/* ... shortened content for clarity */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
