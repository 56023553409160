import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import "../Styles/heroAnimate.css"; // Ensure this path is correct
import {
  FaTruck,
  FaBox,
  FaHandshake,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { IoAirplaneOutline } from "react-icons/io5";
import { GiBattleship } from "react-icons/gi";
import { LuWarehouse } from "react-icons/lu";

const services = [
  {
    id: 1,
    icon: <FaTruck />,
    title: "Reliable Delivery",
    description: "On-time delivery with real-time tracking and communication, 24/7 support.",
    image: "/path/to/image1.jpg",
  },
  {
    id: 2,
    icon: <LuWarehouse />,
    title: "Warehousing",
    description: "We offer shared and dedicated warehousing solutions supported by state-of-the-art technology.",
    image: "/path/to/image2.jpg",
  },
  {
    id: 3,
    icon: <FaHandshake />,
    title: "Exceptional Service",
    description: "Friendly support and customer service available 24/7, ensuring satisfaction.",
    image: "/path/to/image3.jpg",
  },
  {
    id: 4,
    icon: <GiBattleship />,
    title: "Ocean/Sea Transport",
    description: "Full ocean and sea transportation services for global freight.",
    image: "/path/to/image4.jpg",
  },
  {
    id: 5,
    icon: <IoAirplaneOutline />,
    title: "Air Freight",
    description: "Fast and reliable air freight solutions with a focus on safety and efficiency.",
    image: "/path/to/image5.jpg",
  },
  {
    id: 6,
    icon: <FaBox />,
    title: "Secure Packaging",
    description: "Confidential and secure packaging for mail delivery worldwide.",
    image: "/path/to/image6.jpg",
  },
];

const ITEMS_PER_PAGE = 3;

const HeroAnimate = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [direction, setDirection] = useState(1);

  const handleNext = () => {
    setDirection(1);
    setCurrentPage((prevPage) => (prevPage + 1) % Math.ceil(services.length / ITEMS_PER_PAGE));
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentPage((prevPage) =>
      (prevPage - 1 + Math.ceil(services.length / ITEMS_PER_PAGE)) % Math.ceil(services.length / ITEMS_PER_PAGE)
    );
  };

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const currentServices = services.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  // Create a spring for the card animations
  const cardSpring = useSpring({
    opacity: 1,
    scale: 1,
    from: { opacity: 0, scale: 0.9 },
    reset: true,
    config: { tension: 280, friction: 60 },
  });

  return (
    <div className="container py-20 mb-40">
      <div className="d-flex align-items-center my-40">
        <button className="me-3 d-none d-md-block" onClick={handlePrev}>
          <FaChevronLeft size={24} />
        </button>
        <div className="flex-grow-1">
          <div className="row justify-content-center">
            {currentServices.length === 0 ? (
              <p>No services available</p>
            ) : (
              currentServices.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <animated.div className="card" style={cardSpring}>
                    <div className="card-body text-center">
                      <h5 className="display-1 m-auto text-center fs-2 text-sky-500">
                        {service.icon}
                      </h5>
                      <h3 className="card-title text-center text-uppercase font-semibold font-sans fs-5 text-sky-500">
                        {service.title}
                      </h3>
                      <p className="card-text font-sans text-sky-500 text-capitalize text-sm">
                        {service.description}
                      </p>
                    </div>
                  </animated.div>
                </div>
              ))
            )}
          </div>
        </div>
        <button className="ms-3 d-none d-md-block" onClick={handleNext}>
          <FaChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default HeroAnimate;
