import React, { useState } from "react";
import "../App.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import FAQImage from "../assets/Forklift.png"; // Replace with your image path

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => setIsOpen(!isOpen);

  return (
    <div className="faq-item border-bottom p-3">
      <div className="d-flex justify-content-between align-items-center" onClick={toggleAnswer}>
        <h6 className="mb-0">{question}</h6>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isOpen && <p className="mt-2 text-muted">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "What is your return policy?",
      answer: "You can return any unused item within 30 days for a full refund.",
    },
    {
      question: "How long does shipping take?",
      answer: "Shipping usually takes 5-7 business days within the continental US.",
    },
    {
      question: "Do you ship internationally?",
      answer: "Yes, we ship to over 50 countries worldwide.",
    },
    {
      question: "How can I track my order?",
      answer: "Once your order ships, you will receive an email with a tracking number.",
    },
  ];

  return (
    <div className="faq-container container mt-4 mb-5">
      <div className="row">
        {/* Left Column with Image */}
        <div className="col-md-4 d-flex align-items-center justify-content-center mb-4 mb-md-0">
          <img
            src={FAQImage}
            alt="FAQ"
            loading="lazy"
            className="img-fluid rounded"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        {/* Right Column with FAQ Items */}
        <div className="col-md-8">
          <h4 className="text-center mb-4 text-uppercase font-serif">Frequently Asked Questions</h4>
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
