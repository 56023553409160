import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaEnvelope, FaPhoneVolume } from "react-icons/fa";
import LogoImage from "../assets/LOGO.png";
import LogoIcon from "../assets/LOGO-ICON.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu function
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);

  return (
    <>
      <nav className="bg-black dark:bg-gray-900 fixed w-full h-auto z-20 top-0 start-0  border-gray-200 dark:border-gray-600">
        <div className="top-header bg-black text-pretty text-white py-2">
          <div className="container">
            <div className="row">
              {/* Logo (Visible on Mobile Only) */}
              <div className="col-12 col-md-4 flex justify-center mb-2">
                <img src={LogoImage} alt=" Golden Airway Courier Logo" className="h-8" />
              </div>
              {/* Contact Info (Hidden on Mobile) */}
              <div className="col-md-6 hidden md:flex items-center">
                <div className="contact-info">
                <ul className="flex space-x-4 text-decoration-none">
                  <li className="flex items-center">
                    <FaEnvelope className="text-gray-300 mr-2" />
                    <a
                      href="mailto:info@goldenairwaycourier.com"
                      className="text-gray-300 hover:text-white  text-decoration-none"
                    >
                      info@goldenairwaycourier.com
                    </a>
                  </li>
                  <li className="flex items-center ">
                    <FaPhoneVolume className="text-gray-300 mr-2" />
                    <a
                      href="tel:+447543878790"
                      className="text-gray-300 hover:text-white text-decoration-none"
                    >
                      +44 7543 878790
                    </a>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center ml-12 text-2xl font-semibold whitespace-nowrap dark:text-white">
              <img src={LogoIcon} alt="Logo" className="h-8" />
            </span>
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between ${
              isMenuOpen ? "flex" : "hidden"
            } w-full md:flex md:w-auto md:order-1`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-2 md:p-0 mt-0 font-medium text-left md:text-left md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white rounded-md dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 text-decoration-none">
              <li>
                <NavLink
                  to="/"
                  className="block py-2 px-3 text-gray-600 rounded hover:bg-gray-100 md:bg-transparent md:text-gray-700 md:p-0 md:dark:text-blue-500 text-decoration-none"
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className="block py-2 px-3 text-gray-600 rounded hover:bg-gray-100 md:bg-transparent md:text-gray-700 md:p-0 md:dark:text-blue-500 text-decoration-none"
                  aria-current="page"
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="block py-2 px-3 text-gray-600 rounded hover:bg-gray-100 md:bg-transparent md:text-gray-700 md:p-0 md:dark:text-blue-500 text-decoration-none"
                  aria-current="page"
                >
                  Our Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/tracking"
                  className="block py-2 px-3 text-gray-600 rounded hover:bg-gray-100 md:bg-transparent md:text-blue-gray md:p-0 md:dark:text-blue-500 text-decoration-none"
                  aria-current="page"
                >
                  Track and Trace Shipment
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/contact"
                  className="block py-2 px-3 text-gray-600 rounded hover:bg-gray-100 md:bg-transparent md:text-blue-gray md:p-0 md:dark:text-blue-500 text-decoration-none"
                  aria-current="page"
                >
                  Contact Us{" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
