import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Homepage";
import ServicesPage from "../pages/Servicespage";
import AboutPage from "../pages/Aboutpage";
import Trackingpage from "../pages/Trackingpage";
import Header from "../Components/Header";
import Contactpage from "../pages/Contactpage";
const AppRoutes = () => {
  return (
    <div className="overflow-x-hidden">
<Header/>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/tracking" element={<Trackingpage />} />
      <Route path="/contact" element={<Contactpage />} />
    </Routes>
    </div>
  );
};

export default AppRoutes;
