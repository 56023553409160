import React from 'react'
import ContactForm from '../Components/ContactForm'

const Contactpage = () => {
  return (
    <div className='mt-80 '>
      <ContactForm />
    </div>
  )
}

export default Contactpage