import React from "react";
import { useLocation } from "react-router-dom";

import Footer from "../Components/Footer";
import AppRoutes from "../Layout/AppRoutes";
import SEO from "../helpers/SEO/SEO";

const Layout = () => {
  const location = useLocation();
  const { pathname } = location;

  let titleData;

  if (pathname === "/") {
    titleData = {
      title: "Golden-Airway-Courier-Home",
      metaDescription:
        "Golden-Airway-Courier this is our home page where you can find the all about us and updates about the courier services.",
    };
  } else if (pathname === "/about") {
    titleData = {
      title: "Golden-Airway-Courier-About",
      metaDescription:
        "Golden-Airway-Courier-about this about page where you can read all about us.",
    };
  } else if (pathname === "/services") {
    titleData = {
      title: "Golden-Airway-Courier-Services",
      metaDescription:
        "Golden-Airway-Courier-services this services page where you can find the all about our services.",
    };
  } else if (pathname === "/tracking") {
    titleData = {
      title: "Golden-Airway-Courier-Tracking",
      metaDescription:
        "Golden-Airway-Courier-tracking this tracking page where you can find the current location of your shipment.",
    };
  }else if (pathname === "/contact") {
    titleData = {
      title: "Golden-Airway-Courier-Contact",
      metaDescription:
        "Golden-Airway-Courier-Contact this Contact page where you can contact us for any kind for help with your shipment.",
    };
  }
  SEO(titleData);
  return (
    <>
      {/* Header Component here  */}
      <main className="overflow-x-hidden">

        {/* AppRoutes Component here */}
        <AppRoutes />
      </main>
      <footer className="mt-10">
        {/* Footer Component here */}
        <Footer />
      </footer>
    </>
  );
};

export default Layout;
