import React from 'react'
import "../App.css";

const ServiceBanner = () => {
  return (
    <div>
         <div className="service-header mt-40">
        <h2 className="text-left  pt-60 ps-24 text-2xl fw-bold text-white font-serif text-uppercase">Our Services</h2>
        </div>
    </div>
  )
}

export default ServiceBanner