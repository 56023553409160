import React from "react";
import ServicesOne from "../Components/ServicesOne";
import VideoSection from "../Components/VideoSection";
import ServiceBanner from "../Components/ServiceBanner";
import QuoteBanner from "../Components/QuoteBanner";

const Servicespage = () => {
  return (
    <div className="mt-60 container">
      <ServiceBanner />
      <VideoSection />
      <ServicesOne />
      <QuoteBanner />
    </div>
  );
};

export default Servicespage;
