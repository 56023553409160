import React from "react";
import ServiceImg1 from "../assets/serviceImg1.jpg";
import Packaging from "../assets/packaging.jpg";
import warehouse from "../assets/warehousing.jpg";
import Airway from "../assets/airway.jpg";
import Road from "../assets/road.jpg";
import sealing from "../assets/sealing.jpg";

const ServicesOne = () => {
  return (
    <section id="services" className="py-10 pt-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8 text-uppercase">
          Our Services
        </h2>
        <div className="row bg-gray-100">
          {/* Service 1 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-blue-500 text-4xl mb-4 flex justify-center">
                <img src={ServiceImg1} alt="Service 1" loading="lazy" />
              </div>
              <h3 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Sea/Ocean Freight
              </h3>
              <p className="text-gray-600 text-left font-serif fw-medium">
                Golden Airway Courier offers a full range of global, ocean-freight services including FCL, LCL, and consolidation.
              </p>
            </div>
          </div>

          {/* Service 2 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-green-500 text-4xl mb-4 flex justify-center">
                <img src={Packaging} alt="Service 2" loading="lazy" />
              </div>
              <h2 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Secure Packaging
              </h2>
              <p className="text-gray-600 text-left font-serif fw-medium">
                Our packaging solutions keep your items safe during transit, ensuring they arrive in perfect condition.
              </p>
            </div>
          </div>

          {/* Service 3 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-yellow-500 text-4xl mb-4 flex justify-center">
                <img src={warehouse} alt="Service 3" loading="lazy" />
              </div>
              <h3 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Warehousing
              </h3>
              <p className="text-gray-600 text-left font-serif fw-medium">
                We offer shared and dedicated warehousing solutions supported by state-of-the-art technology, available 24/7.
              </p>
            </div>
          </div>

          {/* Service 4 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-yellow-500 text-4xl mb-4 flex justify-center">
                <img src={Airway} alt="Service 4" loading="lazy" />
              </div>
              <h3 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Air Freight
              </h3>
              <p className="text-gray-600 text-left font-serif fw-medium">
                Golden Airway Courier offers professional and reliable global air-freight solutions as an IATA-endorsed air forwarder.
              </p>
            </div>
          </div>

          {/* Service 5 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-yellow-500 text-4xl mb-4 flex justify-center">
                <img src={Road} alt="Service 5" loading="lazy" />
              </div>
              <h3 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Road Transportation
              </h3>
              <p className="text-gray-600 text-left font-serif fw-medium">
                Golden Airway Courier is a trusted partner in domestic road transportation, offering expert services.
              </p>
            </div>
          </div>

          {/* Service 6 */}
          <div className="col-12 col-md-4 mb-6">
            <div className="bg-white p-6 rounded-lg shadow-lg h-full">
              <div className="text-yellow-500 text-4xl mb-4 flex justify-center">
                <img src={sealing} alt="Service 6" loading="lazy" />
              </div>
              <h3 className="text-xl font-bold text-gray-600 mb-4 text-left font-serif text-capitalize">
                Diplomatic Bag and Secure Logistics
              </h3>
              <p className="text-gray-600 text-left font-serif fw-medium">
                Send and receive mail and equipment around the world securely with complete confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
