import React from "react";
import LiftImg from "../assets/lift.jpg";
import { FcCustomerSupport } from "react-icons/fc";
import { FaTruckPlane } from "react-icons/fa6";
import { GiPathDistance } from "react-icons/gi";
import { FaGlobe } from "react-icons/fa";

const HeroGrid = () => {
  return (
    <div className="container ">
      <div className="row d-flex bg-slate-100">
        {/* Left Column with 4 boxes */}
        <div className="col-12 col-md-6 d-flex flex-wrap">
          <div className="col-12 col-sm-6 d-flex flex-column">
            <div className="border m-2 p-3">
              <h6 className="text-center text-uppercase fw-semibold text-green-600 text-decoration-underline">
                Track & Trace
              </h6>
              <div className="d-flex align-items-left justify-content-left m-2 p-2">
                <GiPathDistance style={{ fontSize: "80px", color: "#4CAF50" }} className="animate-bounce" />
                <p className="ps-3 text-pretty text-gray-700 font-serif">
                  Experience the beauty of nature with guided tours to scenic
                  destinations.
                </p>
              </div>
            </div>
            <div className="border m-2 p-3">
              <h6 className="text-center text-uppercase fw-semibold">
                Global Coverage
              </h6>
              <div className="d-flex align-items-left justify-content-left m-2 p-2">
                <FaGlobe style={{ fontSize: "50px", color: "#007BFF" }} className="animate-bounce" />
                <p className="ps-3 text-pretty text-gray-700 font-serif">
                  US, Europe & Worldwide by sea & air. We offer a range of
                  worldwide freight services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 d-flex flex-column">
            <div className="border m-2 p-3">
              <h6 className="text-center text-uppercase fw-semibold text-neutral-600 text-decoration-underline">
                Express Delivery
              </h6>
              <div className="d-flex align-items-left justify-content-left m-2 p-2">
                <FaTruckPlane style={{ fontSize: "50px", color: "#8B4513" }} className="animate-bounce" />
                <p className="ps-3 text-pretty text-gray-700 font-serif">
                  We service your shipments via a diverse operating infrastructure.
                </p>
              </div>
            </div>
            <div className="border m-2 p-3">
              <h6 className="text-center text-uppercase fw-semibold text-orange-500 text-decoration-underline">
                Dedicated Support
              </h6>
              <div className="d-flex align-items-left justify-content-left m-2 p-2">
                <FcCustomerSupport style={{ fontSize: "50px", color: "#FFD700" }} className="animate-bounce" />
                <p className="ps-3 text-pretty text-gray-700 font-serif">
                  Discover historical sites and landmarks with our cultural tours.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column with an Image */}
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <img
            src={LiftImg}
            alt="Destination"
            className="img-fluid"
            loading="lazy" // Lazy loading added here
            style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroGrid;
