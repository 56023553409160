import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import "../Styles/heroSection.css"; // Import the CSS file

const slides = [
  {
    id: 1,
    image: require("../assets/delitruck.jpg"),
    title: "On time delivery makes us better and powerful",
    description: "Arriving on time is our number one priority, destination is never a problem.",
  },
  {
    id: 2,
    image: require("../assets/pull.jpg"),
    title: "Leading Global Delivery services", 
    description: "We offer a wide range of global, oceanic, and international freight.",
  },
  {
    id: 3,
    image: require("../assets/warehouses.jpg"),
    title: "Industrial Warehousing Standards",
    description: "With a proven track record of success and comprehensive, scalable solutions.",
  },
];

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  // Preload images for smoother transitions
  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, []);

  // Slide change with interval, pauses on hover
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }
    }, 3000); // Adjust timing to suit your needs

    return () => clearInterval(interval);
  }, [isHovered]);

  return (
    <div
      className="hero-section"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        className="slide"
        key={slides[currentIndex].id}
        style={{ backgroundImage: `url(${slides[currentIndex].image})` }}
        initial={{ scale: 1 }}
        animate={{ scale: 1.05 }}
        exit={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 10 }}
      >
        <motion.div
          className="overlay"
          initial={{ opacity: 0, x: "-100%" }}
          animate={{ opacity: 1, x: "0%" }}
          exit={{ opacity: 0, x: "100%" }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
          <h2 className="title animate-bounce font-sans fs-4 text-white">
            {slides[currentIndex].title}
          </h2>
          <b className="description animate-pulse text-sky-500">
            {slides[currentIndex].description}
          </b>
          <br />
          <br />
          <a href="/contact" className="tracking-button mt-60 pt-28">
            Contact Us
          </a>
        </motion.div>
      </motion.div>

      {/* Tidio Chat Placeholder */}
      <div className="tidio-placeholder">
        <p>Chat widget will be here soon!</p>
      </div>

      {/* WhatsApp Contact Button */}
      <a
        href="https://wa.me/447543878790"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={40} />
      </a>
    </div>
  );
};

export default HeroSection;
