import React from "react";
import { Helmet } from "react-helmet";

import HeroSection from "../Components/HeroSection";
import HeroAnimate from "../Components/HeroAnimate";
import ParcelTracking from "../Components/ParcelTracking";
import HeroGrid from "../Components/HeroGrid";
import FAQ from "../Components/FAQ";
const Homepage = () => {
  return (
    <div className="mt-5 container-fluid ">
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Golden Airway  Courier",
            "url": "https://goldenairwaycourier.com",
            "logo": "https://goldenairwaycourier.com/logosmall.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+44 7543878790",
              "contactType": "Customer Service, Support",
              "areaServed": "Worldwide",
              "availableLanguage": "English"
            }
          }
          `}
        </script>
        <title>Golden Airway Courier – Leading Logistics Services</title>
        <meta
          name="description"
          content="Golden Airway Courier provides reliable logistics and shipping services worldwide. Track your Shipments easily."
        />
        <meta
          name="keywords"
          content="Golden Airway Courier, logistics, shipping, parcel tracking"
        />
      </Helmet>
      <HeroSection />
      <ParcelTracking />
      <HeroGrid />
      <HeroAnimate />
      <FAQ />
    </div>
  );
};

export default Homepage;
