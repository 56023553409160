import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Prepare the form data for submission
    const data = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      subject: formData.subject,
      message: formData.message
    };

    try {
      const response = await fetch("https://formsubmit.co/info@goldenairwaycourier.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        toast.success("Message sent successfully!");
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
      } else {
        toast.error("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mt-72 mb-60">
      <div className="contact-header">
        <h2 className="text-left mt-5 pt-32 ps-24 text-2xl fw-bold text-white font-serif text-uppercase">Contact Us</h2>
      </div>
      <div className="row">
        {/* Left Column */}
        <div className="col-md-3 d-flex align-items-center justify-content-center mb-4 mb-md-0">
          {/* Optional content or image can be placed here */}
        </div>

        {/* Center Column with Form */}
        <div className="col-md-6 mb-4">
          <div className="text-center">
            <h3 className="text-center fw-medium font-serif text-capitalize mt-10">
              <strong>Get in touch</strong>
            </h3>
            <p className="text-sm text-zinc-700 font-serif">
              Fill out the form below to send us a message. We will get back to you as soon as possible.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            {/* Full Name Field */}
            <div className="form-floating mb-3 fw-bold">
              <input
                type="text"
                className="form-control font-serif text-pretty"
                id="fullName"
                placeholder="John Doe"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              <label htmlFor="fullName">Full Name</label>
            </div>

            {/* Email Address Field */}
            <div className="form-floating mb-3 fw-bold">
              <input
                type="email"
                className="form-control font-serif text-pretty"
                id="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email">Email Address</label>
            </div>

            {/* Phone Number Field */}
            <div className="form-floating mb-3 fw-bold">
              <input
                type="tel"
                className="form-control font-serif text-pretty"
                id="phoneNumber"
                placeholder="123-456-7890"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <label htmlFor="phoneNumber">Phone Number</label>
            </div>

            {/* Subject Field */}
            <div className="form-floating mb-3 fw-bold">
              <input
                type="text"
                className="form-control font-serif text-pretty"
                id="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <label htmlFor="subject">Subject</label>
            </div>

            {/* Message Field */}
            <div className="form-floating mb-3 fw-bold">
              <textarea
                className="form-control font-serif text-pretty"
                id="message"
                placeholder="Leave a message here"
                style={{ height: "150px" }}
                value={formData.message}
                onChange={handleChange}
                required
              />
              <label htmlFor="message">Message</label>
            </div>

            {/* Submit Button */}
            <div className="mt-4 text-center fw-bold">
              <button type="submit" className="btn btn-primary w-50 mb-10 fw-bold" disabled={isSubmitting}>
                {isSubmitting ? (
                  <ThreeDots color="#ffffff" height={20} width={20} className="text-center" />
                ) : (
                  "Send Message"
                )}
              </button>
            </div>
          </form>
        </div>

        {/* Right Column */}
        <div className="col-md-3 d-flex align-items-center justify-content-center mb-4 mb-md-0">
          {/* Optional content or image can be placed here */}
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ContactForm;
