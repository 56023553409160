import React from "react";
import ImageSlides from "./ImageSlider";
import Img1 from "../assets/trucks.jpg";
import Img2 from "../assets/parcel.jpg";
import Img3 from "../assets/cargoPlane.jpg";
import Img4 from "../assets/truck2.jpg";
import Img5 from "../assets/plane.jpg";
import "../App.css";

const AboutSectionOne = () => {
  return (
    <div className="pt-1">
      <div className="container">
        <div className="text-left about-caption mb-10 pt-40 px-9 text-white">
          <h1 className="section-title text-5xl text-left text-tertiary px-3 fw-bold">
            About Us
          </h1>
          <h5 className="mb-5">
            Golden Airway Courier is a privately owned, premier international
            freight forwarder, delivery, and logistics service provider.
          </h5>
        </div>
        <div className="row g-3">
          <div className="col-lg-7 col-md-6">
            <div className="row g-3">
              <div className="col-lg-6 col-md-12">
                <img src={Img1} alt="Truck" className="img-fluid" loading="lazy" />
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={Img2} alt="Parcel" className="img-fluid" loading="lazy" />
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={Img3} alt="Cargo Plane" className="img-fluid" loading="lazy" />
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={Img4} alt="Truck 2" className="img-fluid" height={100} loading="lazy" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <p className="text-justify font-medium font-sans">
              At Golden Airway Courier, we take pride in our role as a leading
              logistics provider, driven by a commitment to excellence and
              efficiency. With a dedicated team of over 250 professionals, we
              specialize in the seamless transport and secure storage of a
              diverse range of goods. Our comprehensive services cater to a
              wide array of needs, including the delivery and storage of raw
              materials, manufactured products, palletized freight, and
              equipment parts. We handle everything from medical and
              pharmaceutical supplies, including blood products, to office
              supplies, computer parts, and audio/video equipment. Our
              expertise extends to managing sensitive and valuable items such
              as legal documents, accounting records, and retail goods.
              Whether it's shipping funds, gifts, or lab specimens, Golden
              Airway Courier ensures that your items are managed with the
              utmost care and precision.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-4 flex-wrap-reverse">
          <div className="col-lg-5 col-md-6">
            <h2 className="text-justify font-medium text-pretty font-sans mt-10 text-slate-500">
              Our Global Mission
            </h2>
            <p className="text-justify font-medium font-sans mt-5 max-w-128">
              Golden Express Cargo Logistics carries out full service and
              operations globally, including warehousing & distribution. We know
              how business changes daily and how important it is to keep our
              customers happy. Golden Express Cargo Logistics has extensive
              experience handling and delivering sensitive domestic and
              industrial products, including consumer technology like networking
              equipment, computers, servers, cell phones, and more. We deliver
              real-time, actionable information reliably and ensure optimal
              efficiency by utilizing advanced, custom software systems. Fully
              EDI capable, our systems interface with trading partners to
              provide unprecedented product visibility throughout the entire
              supply chain. We believe in total employee engagement and operate
              an open-door policy to encourage new ideas. At Golden Express
              Cargo Logistics, we pride ourselves on our ability to provide
              support and guidance to our workforce and operate as a cohesive
              team.
            </p>
          </div>
          <div className="col-lg-5 col-md-6 pt-10">
            <img src={Img5} alt="Plane" className="img-fluid" loading="lazy" />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <ImageSlides />
      </div>
    </div>
  );
};

export default AboutSectionOne;
